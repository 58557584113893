<template>
  <div id="Spectrum">
    <div style="z-index: 2">
      <div style="padding: 36px 0 11% 43px">
        <img
          src="@/assets/Lumispectrums.png"
          style="width: 52px; height: 20px"
        />
      </div>
      <div id="bag1">
        <div style="z-index: 2">
          <div style="display: flex; justify-content: center">
            <div style="z-index: 3">
              <img
                src="@/assets/iconBg1.png"
                style="width: 28px; height: 481px; margin-top: 10px"
              />
            </div>
            <div style="margin-left: 36px; z-index: 3">
              <div class="t1">Let's build from here.</div>
              <div class="t2">
                打造真实&全面的就业体验视角<br />为学校群体提供专业、完备的就业知识图谱
              </div>
              <div class="t3">你知道吗？</div>
              <div class="t4">
                2022届毕业生选择企业就业占比出现下降<br />毕业生企业就业占比为<span
                  style="color: #a1fcfd"
                  >34.21%</span
                >，同比2021届下降了<span style="color: #a1fcfd">7.15</span
                >个百分点；
              </div>
              <div class="t5">
                2022届毕业生选择考研的人数在逐渐增多，占比为40.78%，相比2021届<br />上涨6.57个百分点；
              </div>
              <div class="t1" style="font-size: 20px; font-family: SongTi_Bold">
                LumiLe 是什么？
              </div>
              <div class="t2">
                它是提升大学生职业能力，实现高质量就业与职业发展为己任，致力打造<br />有效连接高校与企业的互联网+就业新生态
              </div>
            </div>
          </div>
          <div id="bag2">
            <div style="z-index: 2">
              <div class="t1">
                不清楚自己的特点？<br />不知道自己该朝什么方向发展？<br />如何提高就业竞争力？
              </div>
              <div class="t2">
                知识图谱是指导学习、探究的重要工具，越来越广泛地应用于教学、就业等各环节中。它能助益学生更为科学地认知<br />自我，了解问题和现状；也能协助老师及辅导人员更加全面深入地把握学生的现状、特点，更具针对性及专业性地开<br />展就业帮助工作。
              </div>
            </div>
            <img src="@/assets/iconBg3.png" class="iconBg3" />
          </div>
        </div>
      </div>
    </div>
    <div :class="Online == 'true' ? 'testOffBackground' : 'testBackground'">
      <img
        :src="SRC + '/spectrumstudio/official_temperate/loading.gif'"
        class="loading"
      />
    </div>
    <div id="bag3">
      <div style="display: flex; padding-top: 80px; justify-content: center">
        <div>
          <img src="@/assets/iconBg2.png" style="width: 28px; height: 150px" />
        </div>
        <div style="margin-left: 36px">
          <div class="t1">就业能力提升 数据可视</div>
          <div class="t2">
            预计全球产生的数字化信息的总量在 2025 年达到 175 ZB（Reinsel et
            al。，2018）。<br />这个数字是什么概念呢？<br />如果将这些数字化的信息全部印成书籍并排列整齐，它们的长度将是从地球到冥王星距离的10倍。
          </div>
        </div>
      </div>
    </div>
    <img :src="SRC + '/spectrumstudio/official_temperate/data_visualize.gif'" />
    <div class="Jump">
      <div>世界瞩目，数字化浪潮已来。<br />让技术为教师所用</div>
      <img src="@/assets/robot.png" class="robot" />
    </div>
    <div class="InternetContentProvider">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank"
        >京 ICP 备 2023004140 号
      </a>
    </div>
    <img src="@/assets/bgTop.png" class="bgTop" />
    <img src="@/assets/bgLeft.png" class="bgLeft" />
    <img src="@/assets/bgRight.png" class="bgRight" />
  </div>
</template>

<script>
export default {
  name: 'Spectrum',
  data () {
    return {
      SRC: process.env.VUE_APP_SRC,
      Online: process.env.VUE_APP_Online
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
#Spectrum {
  width: 100%;
  margin: 0 auto;
  color: #ffffff;
  background: #000;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .loading {
    width: 250px;
    height: 250px;
    margin-left: 56%;
  }
  .testBackground {
    background: url("http://static.test.tiantiantongshi.com/spectrumstudio/web_static_02.png")
      no-repeat center / cover;
  }
  .testOffBackground {
    background: url("https://static.oss.lumibayedu.com/spectrumstudio/web_static_02.png")
      no-repeat center / cover;
  }
  #bag1 {
    width: 1200px;
    margin: 0 auto;
    .t1 {
      font-size: 40px;
      font-family: SongTi_Heavy;
      font-weight: 400;
    }
    .t2 {
      font-size: 14px;
      font-family: Heiti SC-Light, Heiti SC;
      font-weight: 300;
      line-height: 22px;
      margin-top: 12px;
    }
    .t3 {
      width: 140px;
      height: 42px;
      border-radius: 26px;
      border: 1px solid #a1fcfd;
      font-size: 14px;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-weight: 600;
      color: #a1fcfd;
      text-align: center;
      line-height: 42px;
      margin: 68px 0 20px;
    }
    .t4 {
      font-size: 20px;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-weight: 600;
      line-height: 36px;
    }
    .t5 {
      font-size: 12px;
      font-family: PingFang HK-Ultralight, PingFang HK;
      font-weight: 200;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.5);
      margin: 30px 0 65px;
    }
  }
  #bag2 {
    width: 1200px;
    height: 100%;
    margin: 47px auto 20px;
    position: relative;
    display: flex;
    .t1 {
      font-size: 14px;
      font-family: PingFang HK-Semibold, PingFang HK;
      font-weight: 600;
      line-height: 30px;
      padding: 94px 0 150px;
      margin-left: 303px;
    }
    .t2 {
      font-size: 12px;
      font-family: PingFang HK-Thin, PingFang HK;
      font-weight: 100;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.8);
      margin-left: 433px;
    }
    .iconBg3 {
      z-index: 1;
      position: absolute;
      left: 300px;
      top: 0;
      width: 770px;
      height: 334px;
    }
  }
  #bag3 {
    .t1 {
      font-size: 20px;
      font-family: SongTi_SemiBold;
      font-weight: 400;
      color: #a1fcfd;
      margin-bottom: 16px;
    }
    .t2 {
      font-size: 12px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .Jump {
    position: relative;
    height: 354px;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    .robot {
      position: absolute;
      top: -50px;
      transform: translateX(50%);
      width: 471px;
      height: 354px;
    }
  }
  .bgTop {
    z-index: 1;
    position: absolute;
    left: 160px;
    top: 0;
    width: 1033px;
    height: 555px;
  }
  .bgLeft {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 380px;
    width: 316px;
    height: 795px;
  }
  .bgRight {
    z-index: 1;
    position: absolute;
    right: 180px;
    top: 380px;
    width: 224px;
    height: 248px;
  }
  .InternetContentProvider {
    text-align: center;
    transform: translateY(-38px);
    a {
      font-size: 12px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #ffffff;
      line-height: 12px;
      text-decoration: none;
    }
  }
}
</style>
